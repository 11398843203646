import 'webp-in-css/polyfill';
import { scrollToElement } from './utils';
import { scrollAnimate } from './utils';
import { init as initSentry } from '../common/Sentry';

const header = document.querySelector('header');
const disableToScrollAnchors = ['privacy-policy', 'error-modal', 'success-modal'];

window.onscroll = function () {
  if (document.body.scrollTop >= 30 || document.documentElement.scrollTop >= 30) {
    header.classList.add('colored', 'fixed');
    header.classList.remove('transparent');
  } else {
    header.classList.add('transparent');
    header.classList.remove('colored', 'fixed');
  }
};

const inputs = document.querySelectorAll('input');

function validate(e) {
  e.preventDefault();
  e.target.parentNode.classList.add('error');
}

inputs.forEach((input) => {
  input.addEventListener('invalid', validate);
});

function isValidUrl(url) {
  const regexp = /^(ftp|http|https):\/\/[^ "]+$/;
  return regexp.test(url);
}

const anchors = document.querySelectorAll('a[href^="#"]');
for (let anchor of anchors) {
  anchor.addEventListener('click', (e) => {
    const elementId = anchor.getAttribute('href').substr(1);
    if (!disableToScrollAnchors.includes(elementId)) {
      e.preventDefault();
      scrollToElement(anchor.getAttribute('href').substr(1));
    }
  });
}

scrollAnimate().init();

initSentry('en');
